@charset "utf-8";

// フォント
$body-font-family: -apple-system, 'Helvetica Neue', 'Hiragino Kaku Gothic ProN', 'メイリオ', meiryo, sans-serif;
$global-font-size: 1.6rem;

// サイト横幅
$global-width: 960px;

// 行間
$global-lineheight: 1.6;

// カラー
$maincolor: #2199e8;
$subcolor: #777;
$alert-color: #ec5840;

// ブレイクポイント
$sd: "only screen and (max-width:1023px)"; //スマートフォン&タブレット
$sp: "only screen and (max-width:767px)"; //スマートフォン
$tb: "only screen and (min-width:768px) and (max-width:1023px)"; //タブレット
$pc: "only screen and (min-width:1024px)"; //PC
