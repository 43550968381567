@charset "utf-8";

// ========================================
// core files
// ========================================
@import 'setting'; // 変数定義
@import 'mixins/mixins'; // mixin
@import 'normalize';
/* ================================================================================
 init
================================================================================ */

// box-sizing
// ※IE7以下非対応
*, *:before, *:after {
  -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
}

html {
  font-size: 62.5%; // 1rem = 10px
}
body {
  text-size-adjust: 100%;
  font-family: -apple-system, 'Helvetica Neue', 'Hiragino Kaku Gothic ProN', 'メイリオ', meiryo, sans-serif;
  color: #333;
  @include fs(16);
  line-height: 1.6;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

ul, ol, li, dl, dd {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

a,a:visited {
  color: $maincolor;
  text-decoration: none;
}
@media #{$pc} {
    a:hover {
      color: lighten($maincolor, 15%);
      text-decoration: underline;
    }
}

input[type="text"],
input[type="tel"],
input[type="email"],
select,
textarea {
  padding: 4px 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}
@media #{$sd} {
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  select,
  textarea { font-size: 12px; }
}

/* placeholder */
:placeholder-shown { color: #aaa; }
::-webkit-input-placeholder { color: #aaa; } // Google Chrome, Safari, Opera 15+, Android, iOS
:-moz-placeholder { color: #aaa; opacity: 1; } // Firefox 18-
::-moz-placeholder { color: #aaa; opacity: 1; } // Firefox 19+
:-ms-input-placeholder { color: #aaa; } // IE 10+


/* ================================================================================
 util
================================================================================ */
/* clearfix */
.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

/* PC or SP only */
.spOnly { display: none; }
@media #{$sd} {
  .pcOnly { display: none; }
  .spOnly { display: block; }
}


/* ================================================================================
 common
================================================================================ */
.row {
  margin: 0 auto;
  width: 100%;
  max-width: $global-width;
  &--paragraph-center {
    text-align: center;
  }
}

/* 見出し */
.headline {
  text-align: center;
  &--left {
    text-align: left;
  }
  &__subTitle {
    display: block;
    color: #01A0DB;
    font-size: 1.6rem;
  }
  &__title {
    display: block;
    padding-top: 10px;
    font-size: 1.8rem;
  }
}
@media #{$sp} {
  .headline {
    &__subTitle {
      font-size: 1.2rem;
    }
    &__title {
      padding-top: 10px;
      font-size: 1.8rem;
    }
  }
}

/* 文章 */
.txt {
  padding-top: 20px;
}
@media #{$sp} {
  .txt {
    font-size: 1.2rem;
  }
}

/* ボタン */
.btn {
  display: block;
  margin: 40px auto 0;
  padding: 10px;
  text-align: center;
  width: 240px;
  border: #01A0DB 1px solid;
  border-radius: 100px;
  color: #01A0DB;
  font-weight: bold;
  background: #fff;
  &:hover {
    text-decoration: none;
    background: #01A0DB;
    color: #fff;
    transition-duration: 0.3s;
  }
  &--back {
    // padding: 5px;
    border-color: #aaa;
    color: #aaa;
    &:hover {
      border-color: #01A0DB;
    }
  }

}
@media #{$sp} {
  .btn {
    margin: 20px auto 0;
    padding: 10px;
    width: 200px;
    font-size: 1.4rem;
  }
}
/* 下層メインビジュアル */
.lowerMv {
  position: relative;
  display: table;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 380px;
  &:after {
    position: absolute;
    content: "";
    width: 560px;
    height: 380px;
    background: #F1DF6C;
    right: 0;
    bottom: 0;
    margin-right: -40px;
    margin-bottom: -40px;
    z-index: -1;
  }
  &--company {
    background: url("../../images/company_mv.jpg") center 50%;
  }
  &--service {
    background: url("../../images/service_mv.jpg") center 50%;
  }
  &--contact {
    background: url("../../images/contact_mv.jpg") center 50%;
  }
  &__headline {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    height: 100%;
    color: #fff;
    font-size: 4rem;
    font-weight: bold;
  }
}
@media #{$sd} {
  .lowerMv {
    height: 189px;
    &:after {
      width: 90%;
      height: 20px;
      right: 50%;
      margin-right: -45%;
      bottom: 0;
      margin-bottom: -20px;
    }
    &--company {
      background-size: cover;
    }
    &--service {
      background-size: cover;
    }
    &--contact {
      background-size: cover;
    }
    &__headline {
      font-size: 2rem;
    }
  }
}

/* グローバルナビゲーション */
.gNav a {
  display: inline-block;
  padding: 0 24px;
  border-left: #333 1px solid;
  color: #333;
  font-weight: bold;
  &:hover {
    text-decoration: none;
    opacity: .7;
  }
  &:first-child {
    border-left: none;
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
}
@media screen and (max-width:1100px) {
  .gNav {
    a {
      padding: 0 10px;
      font-size: 1.4rem;
    }
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
}
@media #{$sd} {
  .gNav {
    display: none;
    &--footer {
      display: inline-block;
      padding-top: 20px;
    }
  }
}
@media #{$sp} {
  .gNav {
    &--footer {
      display: block;
      padding-top: 20px;
      a {
        display: block;
        padding: 10px 20px;
        border-left: none;
        &:first-child {
          padding-left: 20px;
        }
        &:last-child {
          padding-right: 20px;
        }
      }
    }
  }
}


/* ================================================================================
 header
================================================================================ */
.header {
  &__inner {
    display: flex;
    padding: 40px;
    align-items: center;
    justify-content: space-between;
  }
  &__logo {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    font-weight: normal;
    a {
      display: block;
      line-height: 0;
      &:hover {
        opacity: .7;
      }
      img {
        margin-right: 20px;
        width: 100%;
        max-width: 146px;
      }
    }
  }
}
@media #{$sd} {
  .header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    background: #fff;
    border-bottom: #ddd 1px solid;
    &__inner {
      padding: 20px 20px 10px;
    }
    &__logo {
      display: block;
      font-size: 1.2rem;
      a {
        img {
          margin-right: 0;
          padding-bottom: 10px;
          width: 100%;
          max-width: 100px;
        }
      }
    }
  }
}


/* ================================================================================
 drawer
================================================================================ */
.drawer {
  display: none;
}
.drawerBtn {
  display: none;
}
.drawerBtn__label {
  display: none;
}
#drawerOverray {
  display: none;
}
@media #{$sd} {
  .drawer {
    display: table;
    position: fixed;
    opacity: 0;
    overflow: hidden;
    top: -1000px;
    left: 0;
    z-index: 101;
    width: 100%;
    background: #fff;
    -webkit-transition: .5s;
    transition: .5s;
    a {
      position: relative;
      display: inline-block;
      margin-top: 30px;
      color: #333;
      &:hover {
        opacity: .8;
      }
      &:visited {
        color: #aaa;
      }
    }
    &__list {
      display: table-cell;
      vertical-align: middle;
    }
    &__item {
      text-align: center;
      font-size: 2rem;

    }
    &__ttl {
      font-weight: bold;
      color: #333;
    }
  }
  .drawerBtn {
    display: block;
    position: fixed;
    z-index: 1001;
    top: 0;
    right: 0;
    padding: 40px 40px 28px;
    transition: .3s;
    &__inner {
      position: relative;
      width: 42px;
      height: 25px;
    }
    &__inner span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background: #000;
      left: 0;
      transition: .35s ease-in-out;
    }
    &__inner span:nth-child(1) {
      top: 0;
    }
    &__inner span:nth-child(2) {
      top: 10px;
    }
    &__inner span:nth-child(3) {
      top: 20px;
    }
    &__label {
      display: block;
      text-align: center;
      padding-top: 4px;
      font-size: 1rem;
      font-weight: bold;
    }
  }
  #drawerOverray {
    position: fixed;
    opacity: 0;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    -webkit-transition: .2s;
    transition: .2s;
  }
  body.drawerOpen {
    overflow: hidden;
    .drawerBtn {
      z-index: 1001;
      top: 5px;
      right: 0;
      border-left: none;
    }
    .drawerBtn__label {
      display: none;
    }
    .drawerBtn__inner {
      span:nth-child(1) {
        top: 8px;
        transform: rotate(315deg);
      }
      span:nth-child(2) {
        width: 0;
        left: 50%;
      }
      span:nth-child(3) {
        top: 8px;
        transform: rotate(-315deg);
      }
    }
    .drawer {
      opacity: 1;
      top: 0;
      height: 100vh;
    }
    #drawerOverray {
      display: block;
      opacity: 1;
      top: 0;
      z-index: 99;
    }
  }
}
@media #{$sd} {
  .drawerBtn {
    padding: 20px 20px 15px;
    border-left: #ddd 1px solid;
  }
}

/* ================================================================================
 footer
================================================================================ */
.footer {
  padding: 30px 40px;
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__logo {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    font-weight: normal;
    a {
      display: block;
      line-height: 0;
      &:hover {
        opacity: .7;
      }
      img {
        margin-right: 20px;
        width: 150px;
      }
    }
  }
  &__copy {
    padding-top: 30px;
    text-align: right;
    font-size: 1.2rem;
  }
}
@media #{$sd} {
  .footer {
    margin: 0 auto;
    padding: 40px 20px 20px;
    text-align: center;
    &__inner {
      display: block;
    }
    &__logo {
      display: block;
      font-size: 1.2rem;
      a {
        img {
          margin-right: 0;
          width: 90px;
        }
      }
      p {
        padding-top: 20px;
      }
    }
    &__copy {
      padding-top: 20px;
      text-align: center;
    }
  }
}


/* ================================================================================
 main visual
================================================================================ */
.mv {
  margin: 0 40px;
  &__img {
    position: relative;
    background: url("../../images/mv.jpg") center 50%;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 560px;
    &:after {
      position: absolute;
      content: "";
      width: 560px;
      height: 560px;
      background: #F1DF6C;
      right: 0;
      bottom: 0;
      margin-right: -40px;
      margin-bottom: -40px;
      z-index: -1;
    }
  }
  &__inner {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 80px 20px 0;
  }
  &__logo {
    padding-right: 60px;
    border-right: #fff 2px solid;
    img {
      width: 350px;
    }
  }
  &__copy {
    padding-left: 60px;
    img {
      width: 396px;
    }
  }
}
@media #{$sd} {
  .mv {
    margin: 80px 0 0;
    text-align: center;
    &__img {
      width: 100%;
      height: 380px;
      &:after {
        width: 90%;
        height: 252px;
        right: 50%;
        margin-right: -45%;
        margin-bottom: -20px;
      }
    }
    &__inner {
      display: block;
      padding: 36px 10px 0;
    }
    &__logo {
      padding-right: 0;
      padding-bottom: 17px;
      border-right: none;
      img {
        width: 140px;
      }
    }
    &__copy {
      display: inline-block;
      padding-top: 15px;
      border-top: #fff 1px solid;
      padding-left: 0;
      img {
        width: 198px;
      }
    }
  }
}


/* ================================================================================
 serviceList
================================================================================ */
.serviceList {
  margin-top: 120px;
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: #ddd 1px solid;
    &:last-child {
      border-bottom: #ddd 1px solid;
    }
  }
  &__image {
    width: 40%;
    line-height: 0;
    img {
      width: 100%;
    }
  }
  &__info {
    margin: 0 auto;
    padding: 20px;
    width: 540px;
  }
  &__btn {
    .btn {
      margin-left: 0;
    }
  }
}
@media #{$sp} {
  .serviceList {
    margin-top: 60px;
    padding-bottom: 40px;
    &__item {
      display: block;
      border: none;
      &:last-child {
        border: none;
      }
      &--sp {
        display: flex;
        flex-direction: column;
      }
    }
    &__image {
      width: 90%;
      &--sp {
        order: 1;
        margin-left: auto;
        padding-top: 40px;
      }
    }
    &__info {
      margin: 0 auto;
      padding: 20px 30px 0;
      width: 100%;
      &--sp {
        order: 2;
      }
    }
    &__btn {
      .btn {
        margin-left: auto;
      }
    }
  }
}

/* ================================================================================
 challenge
================================================================================ */
.challenge {
  margin: 120px auto;
  width: 68%;
  &__list {
    display: flex;
    justify-content: space-between;
    padding-top: 80px;
  }
  &__item {
    text-align: center;
    padding-left: 1%;
    width: 33.3%;
    &:first-child {
      padding-left: 0;
    }
  }
  &__img {
    width: 100%;
    line-height: 0;
    max-width: 120px;
    margin: 0 auto;
    img {
      width: 100%;
    }
    &--large {
      max-width: 240px;
      img {
        width: 100%;
      }
    }
  }
  &__ttl {
    padding-top: 40px;
    font-size: 2rem;
    font-weight: bold;
  }
}
@media #{$sd} {
  .challenge {
    width: 90%;
  }
}
@media #{$sp} {
  .challenge {
    margin: 0 auto;
    padding: 40px 0 60px;
    width: 100%;
    border-top: #ddd 1px solid;
    &__list {
      display: block;
      padding-top: 0;
    }
    &__item {
      padding-left: auto;
      padding-top: 60px;
      width: 100%;
    }
    &__img {
      margin: 0 auto;
      width: 120px;
      img {
        width: 100%;
      }
      &--large {
        width: 186px;
        img {
          width: 100%;
        }
      }
    }
    &__ttl {
      padding-top: 30px;
      font-size: 1.8rem;
    }
  }
}


/* ================================================================================
 office
================================================================================ */
.office {
  padding: 60px 0;
  background: #CCECF8;
  &__list {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding-top: 60px;
    width: 68%;
  }
  &__item {
    width: 100%;
    max-width: 440px;
    padding: 0 10px;
  }
  &__map {
    line-height: 0;
  }
  &__name {
    padding-top: 20px;
    font-size: 2rem;
    font-weight: bold;
  }
  &__address {
    padding-top: 20px;
  }
}
@media #{$sd} {
  .office {
    &__list {
      width: 90%;
    }
  }
}
@media #{$sp} {
  .office {
    padding: 40px 20px 60px;
    &__list {
      display: block;
      padding-top: 0;
      width: 100%;
    }
    &__item {
      margin: 0 auto;
      padding: 40px 0 0;
      max-width: 100%;
    }
    &__name {
      font-size: 1.8rem;
      font-weight: bold;
    }
    &__address {
      padding-top: 10px;
      font-size: 1.2rem;
    }
  }
}

/* ================================================================================
 leadToContact
================================================================================ */
.leadToContact {
  padding: 60px 0;
  background: #01506E;
  text-align: center;
  color: #fff;
  .headline__subTitle {
    color: #fff;
  }
  &__btn {
    display: inline-block;
    margin-top: 40px;
    border: #fff 2px solid;
    border-radius: 100px;
    .btn {
      margin-top: 0;
      color: #333;
      border: #01506E 2px solid;
      &:hover {
        color: #fff;
        border-color: fff;
        background: #01506E;
      }
    }
  }
}
@media #{$sp} {
  .leadToContact {
    padding: 40px 0;
    .txt {
      text-align: center;
    }
    &__btn {
      margin-top: 20px;
    }
  }
}

/* ================================================================================
 company page
================================================================================ */
/* message */
.message {
  margin: 80px auto;
  width: 50%;
  min-width: 720px;
  &__copy {
    padding: 60px 0 80px;
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
  }
  &__name {
    padding-top: 40px;
    text-align: right;
  }
}
@media #{$sd} {
  .message {
    margin: 60px auto 40px;
    padding: 0 20px;
    width: 100%;
    min-width: auto;
    &__copy {
      padding: 20px 0 0;
      font-size: 1.6rem;
    }
    &__name {
      padding-top: 20px;
      font-size: 1.2rem;
    }
  }
}

/* profile */
.profile {
  padding: 80px 0;
  margin: 0 auto;
  width: 50%;
  min-width: 720px;
  border-top: #ddd 1px solid;
}
.profileTable {
  margin: 40px auto 80px;
  width: 100%;
  &__row {
    th {
      padding: 16px 20px;
      text-align: left;
      width: 30%;
    }
    td {
      padding: 16px 10px;
      width: 70%;
    }
    &--bgBlue {
      background: #EBF7FC;
    }
  }
}
@media #{$sd} {
  .profile {
    padding: 40px 20px;
    width: 100%;
    min-width: auto;
  }
  .profileTable {
    margin: 20px auto 0;
    padding: 0 20px;
    &__row {
      font-size: 1.2rem;
      th {
        padding: 16px 10px;
        width: 35%;
      }
      td {
        padding: 16px 10px 16px 0;
        width: 65%;
        p {
          &:nth-child(2) {
            padding-top: 20px;
          }
        }
      }
    }
  }
}

/* access map */
.accessMap {
  margin: 0 auto;
  padding: 80px 0 120px;
  width: 50%;
  min-width: 720px;
  border-top: #ddd 1px solid;
  &__wrap {
    padding-top: 40px;
  }
  &__info {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    padding-top: 60px;
    &:first-child {
      padding-top: 0;
    }
  }
  &__office {
    width: 66%;
  }
  &__name {
    font-size: 2rem;
    font-weight: bold;
  }
  &__addres {
    padding-top: 10px;
  }
  &__btn {
    width: 34%;
    .btn {
      margin: 0;
      margin-left: auto;
    }
  }
  &__map {
    padding-top: 30px;
    width: 100%;
    line-height: 0;
    iframe {
      height: 280px;
    }
  }
}
@media #{$sd} {
  .accessMap {
    padding: 40px 20px;
    width: 100%;
    min-width: auto;
    &__wrap {
      padding-top: 30px;
    }
    &__info {
      display: flex;
      flex-direction: column;
      padding-top: 40px;
    }
    &__office {
      width: 100%;
      order: 1;
    }
    &__name {
      text-align: center;
      font-size: 1.8rem;
    }
    &__addres {
      font-size: 1.2rem;
    }
    &__btn {
      padding-top: 20px;
      width: 100%;
      order: 3;
      .btn {
        margin: 0 auto;
      }
    }
    &__map {
      padding-top: 20px;
      order: 2;
      iframe {
        height: 140px;
      }
    }
  }
}


/* ================================================================================
 service page
================================================================================ */
/* service */
.service {
  margin: 80px auto 0;
  width: 50%;
  min-width: 720px;
  &__item {
    padding: 80px 0;
    border-top: #ddd 1px solid;
    &:first-child {
      padding-top: 0;
      border: none;
    }
  }
  &__img {
    padding-top: 60px;
    width: 100%;
  }
  &__txt {
    padding-top: 60px;
    .txt {
      &:first-child {
        padding-top: 0;
      }
    }
  }
}
@media #{$sd} {
  .service {
    margin: 60px auto 0;
    padding: 0 20px;
    width: 100%;
    min-width: auto;
    &__item {
      padding: 40px 0;
    }
    &__img {
      padding-top: 30px;
      img {
        width: 100%;
      }
    }
    &__txt {
      padding-top: 30px;
    }
  }
}


/* ================================================================================
 contact page
================================================================================ */
.contact {
  border-bottom: #01506E 20px solid;
  &__inner {
    margin: 0 auto;
    padding: 80px 0 140px;
    text-align: center;
    width: 42%;
    min-width: 600px;
  }
}
.contactForm {
  padding-top: 40px;
  &__item {
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding-top: 20px;
    &:first-of-type {
      padding-top: 0;
    }
    &--textarea {
      display: block;
      .contactForm__th {
        width: 100%;
      }
      .contactForm__td {
        padding-top: 20px;
      }
    }
  }
  &__label {
    display: flex;
    align-items: center;
    width: 37%;
    font-weight: bold;
  }
  &__tag {
    margin-right: 10px;
    padding: 2px 10px;
    width: 44px;
    font-size: 1.2rem;
    background: #aaa;
    color: #fff;
    &--required {
      background: #E80B6F;
    }
  }
  &__txt {
    // padding-top: 10px;
    font-size: 1.2rem;
    text-align: left;
  }
  &__input {
    width: 63%;
    input {
      width: 100%;
      padding: 1px 10px;
      background: #FAFAFA;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
    &--textarea {
      margin-top: 20px;
      width: 100%;
      height: 180px;
      textarea {
        width: 100%;
        height: 180px;
        padding: 1px 10px;
        background: #FAFAFA;
        border: 1px solid #ccc;
        border-radius: 4px;
      }
    }
  }
  &--confirm {
    .contactForm__item {
      padding: 15px 20px;
      &:nth-child(odd) {
        background: #EBF7FC;
      }
    }
    .contactForm__label {
    }
    .contactForm__input {
      text-align: left;
    }
  }
}
@media #{$sp} {
  .contact {
    margin-top: 60px;
    &__inner {
      padding: 60px 20px 40px;
      text-align: center;
      width: 100%;
      min-width: auto;
    }
  }
  .contactForm {
    &__item {
      display: block;
    }
    &__label {
      padding-top: 8px;
      width: 100%;
      label {
        font-size: 1.4rem;
      }
      &:first-of-type {
        padding-top: 0;
      }
    }
    &__tag {
      padding: 0 2px;
      width: 40px;
      font-size: 1.2rem;
    }
    &__input {
      padding-top: 8px;
      width: 100%;
      input {
        width: 100%;
        height: 26px;
      }
      &--textarea {
        margin-top: 8px;
        padding-top: 0;
      }
    }
  }
}
.submit {
  &[disabled] {
    border-color: #ddd;
    color: #fff;
    background: #ddd
  }
}
.alertarea {
  padding-top: 10px;
  text-align: left;
  color: #E80B6F;
  font-size: 1.2rem;
}
.errorArea {
  margin-top: 40px;
  padding: 20px;
  border: 1px solid #aaa;
  background: #eee;
  width: 100%;
}


/* ================================================================================
 home
================================================================================ */
@import 'pages/_home.scss';
