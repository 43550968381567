@charset "UTF-8";
/*! normalize.css 2012-01-31T16:06 UTC - http://github.com/necolas/normalize.css */
/* =============================================================================
   HTML5 display definitions
   ========================================================================== */
/*
 * Corrects block display not defined in IE6/7/8/9 & FF3
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block; }

/*
 * Corrects inline-block display not defined in IE6/7/8/9 & FF3
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1; }

/*
 * Prevents modern browsers from displaying 'audio' without controls
 */
audio:not([controls]) {
  display: none; }

/*
 * Addresses styling for 'hidden' attribute not present in IE7/8/9, FF3, S4
 * Known issue: no IE6 support
 */
[hidden] {
  display: none; }

/* =============================================================================
   Base
   ========================================================================== */
/*
 * 1. Corrects text resizing oddly in IE6/7 when body font-size is set using em units
 *    http://clagnut.com/blog/348/#c790
 * 2. Prevents iOS text size adjust after orientation change, without disabling user zoom
 *    www.456bereastreet.com/archive/201012/controlling_text_size_in_safari_for_ios_without_disabling_user_zoom/
 */
html {
  font-size: 100%;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 2 */ }

/* 
 * Addresses font-family inconsistency between 'textarea' and other form elements.
 */
html,
button,
input,
select,
textarea {
  font-family: sans-serif; }

/*
 * Addresses margins handled incorrectly in IE6/7
 */
body {
  margin: 0; }

/* =============================================================================
   Links
   ========================================================================== */
/*
 * Addresses outline displayed oddly in Chrome
 */
a:focus {
  outline: thin dotted; }

/*
 * Improves readability when focused and also mouse hovered in all browsers
 * people.opera.com/patrickl/experiments/keyboard/test
 */
a:hover,
a:active {
  outline: 0; }

/* =============================================================================
   Typography
   ========================================================================== */
/*
 * Neutralise smaller font-size in 'section' and 'article' in FF4+, Chrome, S5
 * Fix IE6/7 heading font-size not being relative to the root font-size
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

h2 {
  font-size: 1.5em;
  margin: 0.83em 0; }

h3 {
  font-size: 1.17em;
  margin: 1em 0; }

h4 {
  font-size: 1em;
  margin: 1.33em 0; }

h5 {
  font-size: 0.83em;
  margin: 1.67em 0; }

h6 {
  font-size: 0.75em;
  margin: 2.33em 0; }

/*
 * Addresses styling not present in IE7/8/9, S5, Chrome
 */
abbr[title] {
  border-bottom: 1px dotted; }

/*
 * Addresses style set to 'bolder' in FF3+, S4/5, Chrome
*/
b,
strong {
  font-weight: bold; }

blockquote {
  margin: 1em 40px; }

/*
 * Addresses styling not present in S5, Chrome
 */
dfn {
  font-style: italic; }

/*
 * Addresses styling not present in IE6/7/8/9
 */
mark {
  background: #ff0;
  color: #000; }

/*
 * Corrects font family set oddly in IE6, S4/5, Chrome
 * en.wikipedia.org/wiki/User:Davidgothberg/Test59
 */
pre,
code,
kbd,
samp {
  font-family: monospace, serif;
  _font-family: 'courier new', monospace;
  font-size: 1em; }

/*
 * Improves readability of pre-formatted text in all browsers
 */
pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word; }

/*
 * 1. Addresses CSS quotes not supported in IE6/7
 * 2. Addresses quote property not supported in S4
 */
/* 1 */
q {
  quotes: none; }

/* 2 */
q:before,
q:after {
  content: '';
  content: none; }

small {
  font-size: 75%; }

/*
 * Prevents sub and sup affecting line-height in all browsers
 * gist.github.com/413930
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* =============================================================================
   Lists
   ========================================================================== */
ul,
ol {
  margin-left: 0;
  padding: 0 0 0 40px; }

dd {
  margin: 0 0 0 40px; }

nav ul,
nav ol {
  list-style: none;
  list-style-image: none; }

/* =============================================================================
   Embedded content
   ========================================================================== */
/*
 * 1. Removes border when inside 'a' element in IE6/7/8/9, FF3
 * 2. Improves image quality when scaled in IE7
 *    code.flickr.com/blog/2008/11/12/on-ui-quality-the-little-things-client-side-image-resizing/
 */
img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */ }

/*
 * Corrects overflow displayed oddly in IE9 
 */
svg:not(:root) {
  overflow: hidden; }

/* =============================================================================
   Figures
   ========================================================================== */
/*
 * Addresses margin not present in IE6/7/8/9, S5, O11
 */
figure {
  margin: 0; }

/* =============================================================================
   Forms
   ========================================================================== */
/*
 * Corrects margin displayed oddly in IE6/7
 */
form {
  margin: 0; }

/*
 * Define consistent border, margin, and padding
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/*
 * 1. Corrects color not being inherited in IE6/7/8/9
 * 2. Corrects text not wrapping in FF3 
 * 3. Corrects alignment displayed oddly in IE6/7
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  white-space: normal;
  /* 2 */
  *margin-left: -7px;
  /* 3 */ }

/*
 * 1. Corrects font size not being inherited in all browsers
 * 2. Addresses margins set differently in IE6/7, FF3+, S5, Chrome
 * 3. Improves appearance and consistency in all browsers
 */
button,
input,
select,
textarea {
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
  vertical-align: baseline;
  /* 3 */
  *vertical-align: middle;
  /* 3 */ }

/*
 * Addresses FF3/4 setting line-height on 'input' using !important in the UA stylesheet
 */
button,
input {
  line-height: normal;
  /* 1 */ }

/*
 * 1. Improves usability and consistency of cursor style between image-type 'input' and others
 * 2. Corrects inability to style clickable 'input' types in iOS
 * 3. Removes inner spacing in IE7 without affecting normal text inputs
 *    Known issue: inner spacing remains in IE6
 */
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  /* 1 */
  -webkit-appearance: button;
  /* 2 */
  *overflow: visible;
  /* 3 */ }

/*
 * Re-set default cursor for disabled elements
 */
button[disabled],
input[disabled] {
  cursor: default; }

/*
 * 1. Addresses box sizing set to content-box in IE8/9
 * 2. Removes excess padding in IE8/9
 * 3. Removes excess padding in IE7
      Known issue: excess padding remains in IE6
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
  *height: 13px;
  /* 3 */
  *width: 13px;
  /* 3 */ }

/*
 * 1. Addresses appearance set to searchfield in S5, Chrome
 * 2. Addresses box-sizing set to border-box in S5, Chrome (include -moz to future-proof)
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  /* 2 */
  box-sizing: content-box; }

/*
 * Removes inner padding and search cancel button in S5, Chrome on OS X
 */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none; }

/*
 * Removes inner padding and border in FF3+
 * www.sitepen.com/blog/2008/05/14/the-devils-in-the-details-fixing-dojos-toolbar-buttons/
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/*
 * 1. Removes default vertical scrollbar in IE6/7/8/9
 * 2. Improves readability and alignment in all browsers
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */ }

/* =============================================================================
   Tables
   ========================================================================== */
/* 
 * Remove most spacing between table cells
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

/* ================================================================================
 init
================================================================================ */
*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

html {
  font-size: 62.5%; }

body {
  text-size-adjust: 100%;
  font-family: -apple-system, 'Helvetica Neue', 'Hiragino Kaku Gothic ProN', 'メイリオ', meiryo, sans-serif;
  color: #333;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.6; }

h1, h2, h3, h4, h5, h6, p {
  margin: 0; }

ul, ol, li, dl, dd {
  margin: 0;
  padding: 0;
  list-style-type: none; }

a, a:visited {
  color: #2199e8;
  text-decoration: none; }

@media only screen and (min-width: 1024px) {
  a:hover {
    color: #66b9ef;
    text-decoration: underline; } }

input[type="text"],
input[type="tel"],
input[type="email"],
select,
textarea {
  padding: 4px 10px;
  border: 1px solid #ddd;
  border-radius: 4px; }

@media only screen and (max-width: 1023px) {
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  select,
  textarea {
    font-size: 12px; } }

/* placeholder */
:placeholder-shown {
  color: #aaa; }

::-webkit-input-placeholder {
  color: #aaa; }

:-moz-placeholder {
  color: #aaa;
  opacity: 1; }

::-moz-placeholder {
  color: #aaa;
  opacity: 1; }

:-ms-input-placeholder {
  color: #aaa; }

/* ================================================================================
 util
================================================================================ */
/* clearfix */
.clearfix:after {
  content: "";
  display: block;
  clear: both; }

/* PC or SP only */
.spOnly {
  display: none; }

@media only screen and (max-width: 1023px) {
  .pcOnly {
    display: none; }
  .spOnly {
    display: block; } }

/* ================================================================================
 common
================================================================================ */
.row {
  margin: 0 auto;
  width: 100%;
  max-width: 960px; }
  .row--paragraph-center {
    text-align: center; }

/* 見出し */
.headline {
  text-align: center; }
  .headline--left {
    text-align: left; }
  .headline__subTitle {
    display: block;
    color: #01A0DB;
    font-size: 1.6rem; }
  .headline__title {
    display: block;
    padding-top: 10px;
    font-size: 1.8rem; }

@media only screen and (max-width: 767px) {
  .headline__subTitle {
    font-size: 1.2rem; }
  .headline__title {
    padding-top: 10px;
    font-size: 1.8rem; } }

/* 文章 */
.txt {
  padding-top: 20px; }

@media only screen and (max-width: 767px) {
  .txt {
    font-size: 1.2rem; } }

/* ボタン */
.btn {
  display: block;
  margin: 40px auto 0;
  padding: 10px;
  text-align: center;
  width: 240px;
  border: #01A0DB 1px solid;
  border-radius: 100px;
  color: #01A0DB;
  font-weight: bold;
  background: #fff; }
  .btn:hover {
    text-decoration: none;
    background: #01A0DB;
    color: #fff;
    transition-duration: 0.3s; }
  .btn--back {
    border-color: #aaa;
    color: #aaa; }
    .btn--back:hover {
      border-color: #01A0DB; }

@media only screen and (max-width: 767px) {
  .btn {
    margin: 20px auto 0;
    padding: 10px;
    width: 200px;
    font-size: 1.4rem; } }

/* 下層メインビジュアル */
.lowerMv {
  position: relative;
  display: table;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 380px; }
  .lowerMv:after {
    position: absolute;
    content: "";
    width: 560px;
    height: 380px;
    background: #F1DF6C;
    right: 0;
    bottom: 0;
    margin-right: -40px;
    margin-bottom: -40px;
    z-index: -1; }
  .lowerMv--company {
    background: url("../../images/company_mv.jpg") center 50%; }
  .lowerMv--service {
    background: url("../../images/service_mv.jpg") center 50%; }
  .lowerMv--contact {
    background: url("../../images/contact_mv.jpg") center 50%; }
  .lowerMv__headline {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    height: 100%;
    color: #fff;
    font-size: 4rem;
    font-weight: bold; }

@media only screen and (max-width: 1023px) {
  .lowerMv {
    height: 189px; }
    .lowerMv:after {
      width: 90%;
      height: 20px;
      right: 50%;
      margin-right: -45%;
      bottom: 0;
      margin-bottom: -20px; }
    .lowerMv--company {
      background-size: cover; }
    .lowerMv--service {
      background-size: cover; }
    .lowerMv--contact {
      background-size: cover; }
    .lowerMv__headline {
      font-size: 2rem; } }

/* グローバルナビゲーション */
.gNav a {
  display: inline-block;
  padding: 0 24px;
  border-left: #333 1px solid;
  color: #333;
  font-weight: bold; }
  .gNav a:hover {
    text-decoration: none;
    opacity: .7; }
  .gNav a:first-child {
    border-left: none;
    padding-left: 0; }
  .gNav a:last-child {
    padding-right: 0; }

@media screen and (max-width: 1100px) {
  .gNav a {
    padding: 0 10px;
    font-size: 1.4rem; }
  .gNav:first-child {
    padding-left: 0; }
  .gNav:last-child {
    padding-right: 0; } }

@media only screen and (max-width: 1023px) {
  .gNav {
    display: none; }
    .gNav--footer {
      display: inline-block;
      padding-top: 20px; } }

@media only screen and (max-width: 767px) {
  .gNav--footer {
    display: block;
    padding-top: 20px; }
    .gNav--footer a {
      display: block;
      padding: 10px 20px;
      border-left: none; }
      .gNav--footer a:first-child {
        padding-left: 20px; }
      .gNav--footer a:last-child {
        padding-right: 20px; } }

/* ================================================================================
 header
================================================================================ */
.header__inner {
  display: flex;
  padding: 40px;
  align-items: center;
  justify-content: space-between; }

.header__logo {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  font-weight: normal; }
  .header__logo a {
    display: block;
    line-height: 0; }
    .header__logo a:hover {
      opacity: .7; }
    .header__logo a img {
      margin-right: 20px;
      width: 100%;
      max-width: 146px; }

@media only screen and (max-width: 1023px) {
  .header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    background: #fff;
    border-bottom: #ddd 1px solid; }
    .header__inner {
      padding: 20px 20px 10px; }
    .header__logo {
      display: block;
      font-size: 1.2rem; }
      .header__logo a img {
        margin-right: 0;
        padding-bottom: 10px;
        width: 100%;
        max-width: 100px; } }

/* ================================================================================
 drawer
================================================================================ */
.drawer {
  display: none; }

.drawerBtn {
  display: none; }

.drawerBtn__label {
  display: none; }

#drawerOverray {
  display: none; }

@media only screen and (max-width: 1023px) {
  .drawer {
    display: table;
    position: fixed;
    opacity: 0;
    overflow: hidden;
    top: -1000px;
    left: 0;
    z-index: 101;
    width: 100%;
    background: #fff;
    -webkit-transition: .5s;
    transition: .5s; }
    .drawer a {
      position: relative;
      display: inline-block;
      margin-top: 30px;
      color: #333; }
      .drawer a:hover {
        opacity: .8; }
      .drawer a:visited {
        color: #aaa; }
    .drawer__list {
      display: table-cell;
      vertical-align: middle; }
    .drawer__item {
      text-align: center;
      font-size: 2rem; }
    .drawer__ttl {
      font-weight: bold;
      color: #333; }
  .drawerBtn {
    display: block;
    position: fixed;
    z-index: 1001;
    top: 0;
    right: 0;
    padding: 40px 40px 28px;
    transition: .3s; }
    .drawerBtn__inner {
      position: relative;
      width: 42px;
      height: 25px; }
    .drawerBtn__inner span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background: #000;
      left: 0;
      transition: .35s ease-in-out; }
    .drawerBtn__inner span:nth-child(1) {
      top: 0; }
    .drawerBtn__inner span:nth-child(2) {
      top: 10px; }
    .drawerBtn__inner span:nth-child(3) {
      top: 20px; }
    .drawerBtn__label {
      display: block;
      text-align: center;
      padding-top: 4px;
      font-size: 1rem;
      font-weight: bold; }
  #drawerOverray {
    position: fixed;
    opacity: 0;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    -webkit-transition: .2s;
    transition: .2s; }
  body.drawerOpen {
    overflow: hidden; }
    body.drawerOpen .drawerBtn {
      z-index: 1001;
      top: 5px;
      right: 0;
      border-left: none; }
    body.drawerOpen .drawerBtn__label {
      display: none; }
    body.drawerOpen .drawerBtn__inner span:nth-child(1) {
      top: 8px;
      transform: rotate(315deg); }
    body.drawerOpen .drawerBtn__inner span:nth-child(2) {
      width: 0;
      left: 50%; }
    body.drawerOpen .drawerBtn__inner span:nth-child(3) {
      top: 8px;
      transform: rotate(-315deg); }
    body.drawerOpen .drawer {
      opacity: 1;
      top: 0;
      height: 100vh; }
    body.drawerOpen #drawerOverray {
      display: block;
      opacity: 1;
      top: 0;
      z-index: 99; } }

@media only screen and (max-width: 1023px) {
  .drawerBtn {
    padding: 20px 20px 15px;
    border-left: #ddd 1px solid; } }

/* ================================================================================
 footer
================================================================================ */
.footer {
  padding: 30px 40px; }
  .footer__inner {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .footer__logo {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    font-weight: normal; }
    .footer__logo a {
      display: block;
      line-height: 0; }
      .footer__logo a:hover {
        opacity: .7; }
      .footer__logo a img {
        margin-right: 20px;
        width: 150px; }
  .footer__copy {
    padding-top: 30px;
    text-align: right;
    font-size: 1.2rem; }

@media only screen and (max-width: 1023px) {
  .footer {
    margin: 0 auto;
    padding: 40px 20px 20px;
    text-align: center; }
    .footer__inner {
      display: block; }
    .footer__logo {
      display: block;
      font-size: 1.2rem; }
      .footer__logo a img {
        margin-right: 0;
        width: 90px; }
      .footer__logo p {
        padding-top: 20px; }
    .footer__copy {
      padding-top: 20px;
      text-align: center; } }

/* ================================================================================
 main visual
================================================================================ */
.mv {
  margin: 0 40px; }
  .mv__img {
    position: relative;
    background: url("../../images/mv.jpg") center 50%;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 560px; }
    .mv__img:after {
      position: absolute;
      content: "";
      width: 560px;
      height: 560px;
      background: #F1DF6C;
      right: 0;
      bottom: 0;
      margin-right: -40px;
      margin-bottom: -40px;
      z-index: -1; }
  .mv__inner {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 80px 20px 0; }
  .mv__logo {
    padding-right: 60px;
    border-right: #fff 2px solid; }
    .mv__logo img {
      width: 350px; }
  .mv__copy {
    padding-left: 60px; }
    .mv__copy img {
      width: 396px; }

@media only screen and (max-width: 1023px) {
  .mv {
    margin: 80px 0 0;
    text-align: center; }
    .mv__img {
      width: 100%;
      height: 380px; }
      .mv__img:after {
        width: 90%;
        height: 252px;
        right: 50%;
        margin-right: -45%;
        margin-bottom: -20px; }
    .mv__inner {
      display: block;
      padding: 36px 10px 0; }
    .mv__logo {
      padding-right: 0;
      padding-bottom: 17px;
      border-right: none; }
      .mv__logo img {
        width: 140px; }
    .mv__copy {
      display: inline-block;
      padding-top: 15px;
      border-top: #fff 1px solid;
      padding-left: 0; }
      .mv__copy img {
        width: 198px; } }

/* ================================================================================
 serviceList
================================================================================ */
.serviceList {
  margin-top: 120px; }
  .serviceList__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: #ddd 1px solid; }
    .serviceList__item:last-child {
      border-bottom: #ddd 1px solid; }
  .serviceList__image {
    width: 40%;
    line-height: 0; }
    .serviceList__image img {
      width: 100%; }
  .serviceList__info {
    margin: 0 auto;
    padding: 20px;
    width: 540px; }
  .serviceList__btn .btn {
    margin-left: 0; }

@media only screen and (max-width: 767px) {
  .serviceList {
    margin-top: 60px;
    padding-bottom: 40px; }
    .serviceList__item {
      display: block;
      border: none; }
      .serviceList__item:last-child {
        border: none; }
      .serviceList__item--sp {
        display: flex;
        flex-direction: column; }
    .serviceList__image {
      width: 90%; }
      .serviceList__image--sp {
        order: 1;
        margin-left: auto;
        padding-top: 40px; }
    .serviceList__info {
      margin: 0 auto;
      padding: 20px 30px 0;
      width: 100%; }
      .serviceList__info--sp {
        order: 2; }
    .serviceList__btn .btn {
      margin-left: auto; } }

/* ================================================================================
 challenge
================================================================================ */
.challenge {
  margin: 120px auto;
  width: 68%; }
  .challenge__list {
    display: flex;
    justify-content: space-between;
    padding-top: 80px; }
  .challenge__item {
    text-align: center;
    padding-left: 1%;
    width: 33.3%; }
    .challenge__item:first-child {
      padding-left: 0; }
  .challenge__img {
    width: 100%;
    line-height: 0;
    max-width: 120px;
    margin: 0 auto; }
    .challenge__img img {
      width: 100%; }
    .challenge__img--large {
      max-width: 240px; }
      .challenge__img--large img {
        width: 100%; }
  .challenge__ttl {
    padding-top: 40px;
    font-size: 2rem;
    font-weight: bold; }

@media only screen and (max-width: 1023px) {
  .challenge {
    width: 90%; } }

@media only screen and (max-width: 767px) {
  .challenge {
    margin: 0 auto;
    padding: 40px 0 60px;
    width: 100%;
    border-top: #ddd 1px solid; }
    .challenge__list {
      display: block;
      padding-top: 0; }
    .challenge__item {
      padding-left: auto;
      padding-top: 60px;
      width: 100%; }
    .challenge__img {
      margin: 0 auto;
      width: 120px; }
      .challenge__img img {
        width: 100%; }
      .challenge__img--large {
        width: 186px; }
        .challenge__img--large img {
          width: 100%; }
    .challenge__ttl {
      padding-top: 30px;
      font-size: 1.8rem; } }

/* ================================================================================
 office
================================================================================ */
.office {
  padding: 60px 0;
  background: #CCECF8; }
  .office__list {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding-top: 60px;
    width: 68%; }
  .office__item {
    width: 100%;
    max-width: 440px;
    padding: 0 10px; }
  .office__map {
    line-height: 0; }
  .office__name {
    padding-top: 20px;
    font-size: 2rem;
    font-weight: bold; }
  .office__address {
    padding-top: 20px; }

@media only screen and (max-width: 1023px) {
  .office__list {
    width: 90%; } }

@media only screen and (max-width: 767px) {
  .office {
    padding: 40px 20px 60px; }
    .office__list {
      display: block;
      padding-top: 0;
      width: 100%; }
    .office__item {
      margin: 0 auto;
      padding: 40px 0 0;
      max-width: 100%; }
    .office__name {
      font-size: 1.8rem;
      font-weight: bold; }
    .office__address {
      padding-top: 10px;
      font-size: 1.2rem; } }

/* ================================================================================
 leadToContact
================================================================================ */
.leadToContact {
  padding: 60px 0;
  background: #01506E;
  text-align: center;
  color: #fff; }
  .leadToContact .headline__subTitle {
    color: #fff; }
  .leadToContact__btn {
    display: inline-block;
    margin-top: 40px;
    border: #fff 2px solid;
    border-radius: 100px; }
    .leadToContact__btn .btn {
      margin-top: 0;
      color: #333;
      border: #01506E 2px solid; }
      .leadToContact__btn .btn:hover {
        color: #fff;
        border-color: fff;
        background: #01506E; }

@media only screen and (max-width: 767px) {
  .leadToContact {
    padding: 40px 0; }
    .leadToContact .txt {
      text-align: center; }
    .leadToContact__btn {
      margin-top: 20px; } }

/* ================================================================================
 company page
================================================================================ */
/* message */
.message {
  margin: 80px auto;
  width: 50%;
  min-width: 720px; }
  .message__copy {
    padding: 60px 0 80px;
    text-align: center;
    font-size: 2rem;
    font-weight: bold; }
  .message__name {
    padding-top: 40px;
    text-align: right; }

@media only screen and (max-width: 1023px) {
  .message {
    margin: 60px auto 40px;
    padding: 0 20px;
    width: 100%;
    min-width: auto; }
    .message__copy {
      padding: 20px 0 0;
      font-size: 1.6rem; }
    .message__name {
      padding-top: 20px;
      font-size: 1.2rem; } }

/* profile */
.profile {
  padding: 80px 0;
  margin: 0 auto;
  width: 50%;
  min-width: 720px;
  border-top: #ddd 1px solid; }

.profileTable {
  margin: 40px auto 80px;
  width: 100%; }
  .profileTable__row th {
    padding: 16px 20px;
    text-align: left;
    width: 30%; }
  .profileTable__row td {
    padding: 16px 10px;
    width: 70%; }
  .profileTable__row--bgBlue {
    background: #EBF7FC; }

@media only screen and (max-width: 1023px) {
  .profile {
    padding: 40px 20px;
    width: 100%;
    min-width: auto; }
  .profileTable {
    margin: 20px auto 0;
    padding: 0 20px; }
    .profileTable__row {
      font-size: 1.2rem; }
      .profileTable__row th {
        padding: 16px 10px;
        width: 35%; }
      .profileTable__row td {
        padding: 16px 10px 16px 0;
        width: 65%; }
        .profileTable__row td p:nth-child(2) {
          padding-top: 20px; } }

/* access map */
.accessMap {
  margin: 0 auto;
  padding: 80px 0 120px;
  width: 50%;
  min-width: 720px;
  border-top: #ddd 1px solid; }
  .accessMap__wrap {
    padding-top: 40px; }
  .accessMap__info {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    padding-top: 60px; }
    .accessMap__info:first-child {
      padding-top: 0; }
  .accessMap__office {
    width: 66%; }
  .accessMap__name {
    font-size: 2rem;
    font-weight: bold; }
  .accessMap__addres {
    padding-top: 10px; }
  .accessMap__btn {
    width: 34%; }
    .accessMap__btn .btn {
      margin: 0;
      margin-left: auto; }
  .accessMap__map {
    padding-top: 30px;
    width: 100%;
    line-height: 0; }
    .accessMap__map iframe {
      height: 280px; }

@media only screen and (max-width: 1023px) {
  .accessMap {
    padding: 40px 20px;
    width: 100%;
    min-width: auto; }
    .accessMap__wrap {
      padding-top: 30px; }
    .accessMap__info {
      display: flex;
      flex-direction: column;
      padding-top: 40px; }
    .accessMap__office {
      width: 100%;
      order: 1; }
    .accessMap__name {
      text-align: center;
      font-size: 1.8rem; }
    .accessMap__addres {
      font-size: 1.2rem; }
    .accessMap__btn {
      padding-top: 20px;
      width: 100%;
      order: 3; }
      .accessMap__btn .btn {
        margin: 0 auto; }
    .accessMap__map {
      padding-top: 20px;
      order: 2; }
      .accessMap__map iframe {
        height: 140px; } }

/* ================================================================================
 service page
================================================================================ */
/* service */
.service {
  margin: 80px auto 0;
  width: 50%;
  min-width: 720px; }
  .service__item {
    padding: 80px 0;
    border-top: #ddd 1px solid; }
    .service__item:first-child {
      padding-top: 0;
      border: none; }
  .service__img {
    padding-top: 60px;
    width: 100%; }
  .service__txt {
    padding-top: 60px; }
    .service__txt .txt:first-child {
      padding-top: 0; }

@media only screen and (max-width: 1023px) {
  .service {
    margin: 60px auto 0;
    padding: 0 20px;
    width: 100%;
    min-width: auto; }
    .service__item {
      padding: 40px 0; }
    .service__img {
      padding-top: 30px; }
      .service__img img {
        width: 100%; }
    .service__txt {
      padding-top: 30px; } }

/* ================================================================================
 contact page
================================================================================ */
.contact {
  border-bottom: #01506E 20px solid; }
  .contact__inner {
    margin: 0 auto;
    padding: 80px 0 140px;
    text-align: center;
    width: 42%;
    min-width: 600px; }

.contactForm {
  padding-top: 40px; }
  .contactForm__item {
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding-top: 20px; }
    .contactForm__item:first-of-type {
      padding-top: 0; }
    .contactForm__item--textarea {
      display: block; }
      .contactForm__item--textarea .contactForm__th {
        width: 100%; }
      .contactForm__item--textarea .contactForm__td {
        padding-top: 20px; }
  .contactForm__label {
    display: flex;
    align-items: center;
    width: 37%;
    font-weight: bold; }
  .contactForm__tag {
    margin-right: 10px;
    padding: 2px 10px;
    width: 44px;
    font-size: 1.2rem;
    background: #aaa;
    color: #fff; }
    .contactForm__tag--required {
      background: #E80B6F; }
  .contactForm__txt {
    font-size: 1.2rem;
    text-align: left; }
  .contactForm__input {
    width: 63%; }
    .contactForm__input input {
      width: 100%;
      padding: 1px 10px;
      background: #FAFAFA;
      border: 1px solid #ccc;
      border-radius: 4px; }
    .contactForm__input--textarea {
      margin-top: 20px;
      width: 100%;
      height: 180px; }
      .contactForm__input--textarea textarea {
        width: 100%;
        height: 180px;
        padding: 1px 10px;
        background: #FAFAFA;
        border: 1px solid #ccc;
        border-radius: 4px; }
  .contactForm--confirm .contactForm__item {
    padding: 15px 20px; }
    .contactForm--confirm .contactForm__item:nth-child(odd) {
      background: #EBF7FC; }
  .contactForm--confirm .contactForm__input {
    text-align: left; }

@media only screen and (max-width: 767px) {
  .contact {
    margin-top: 60px; }
    .contact__inner {
      padding: 60px 20px 40px;
      text-align: center;
      width: 100%;
      min-width: auto; }
  .contactForm__item {
    display: block; }
  .contactForm__label {
    padding-top: 8px;
    width: 100%; }
    .contactForm__label label {
      font-size: 1.4rem; }
    .contactForm__label:first-of-type {
      padding-top: 0; }
  .contactForm__tag {
    padding: 0 2px;
    width: 40px;
    font-size: 1.2rem; }
  .contactForm__input {
    padding-top: 8px;
    width: 100%; }
    .contactForm__input input {
      width: 100%;
      height: 26px; }
    .contactForm__input--textarea {
      margin-top: 8px;
      padding-top: 0; } }

.submit[disabled] {
  border-color: #ddd;
  color: #fff;
  background: #ddd; }

.alertarea {
  padding-top: 10px;
  text-align: left;
  color: #E80B6F;
  font-size: 1.2rem; }

.errorArea {
  margin-top: 40px;
  padding: 20px;
  border: 1px solid #aaa;
  background: #eee;
  width: 100%; }

/* ================================================================================
 home
================================================================================ */
